import './eventsView.styles.scss'

export const initEventsView = () => {
  const container = document.querySelector('[data-events-container]')

  if (container) {
    const listButton = container.querySelector('[data-list-button]')
    const calendarButton = container.querySelector('[data-calendar-button]')

    listButton.addEventListener('click', () => {
      document.documentElement.classList.remove('calendar-view')
      localStorage.removeItem('event-view')
    })

    calendarButton.addEventListener('click', () => {
      document.documentElement.classList.add('calendar-view')
      localStorage.setItem('event-view', 'calendar-view')
    })
  }
}
