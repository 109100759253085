export function fileInput() {
  const files = document.querySelectorAll('[data-upload-file]')
  files.forEach(file => {
    const fileNameArea = file.querySelector('[data-file-name]')
    const input = file.querySelector('[data-input]')

    input.addEventListener('change', () => {
      const fileName = input.files[0].name
      fileNameArea.innerText = fileName
    })
  })
}
