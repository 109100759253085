export function showMoreButton() {
  const containers = document.querySelectorAll('[data-more-content]')
  if (containers) {
    containers.forEach(container => {
      const fullHeightButton = container.querySelector('[data-more-button]')
      fullHeightButton.addEventListener('click', event => {
        event.preventDefault()
        container.classList.toggle('full-height')
      })
    })
  }
}
