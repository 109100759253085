/**
 * Load for google Recaptcha scripts only when form is in viewport
 *
 * Recaptcha scripts are heavy. If they are loaded with <script>,
 * they are loaded just when page is opened and cause a lot of additional
 * network and CPU load. Also on some pages with no forms, this <script>
 * will be unnecessary at all.
 *
 * Usage (HTML):
 *
 * <form action='/send.php' data-recaptcha-protected></form>
 * <script>window.captchaKey='6LehT9UUAAAAA...'</script>
 *
 * Usage (JS):
 *
 * import lazyRecaptcha from './lazy-recaptcha'
 *
 * const captchaObserver = new IntersectionObserver(entries => {
 *   entries.forEach(entry => {
 *     if (entry.isIntersecting) {
 *       lazyRecaptcha.protectForm(entry.target, form => form.submit())
 *       captchaObserver.unobserve(entry.target)
 *     }
 *   })
 * })
 *
 * document.addEventListener('DOMContentLoaded', () => {
 *   document
 *     .querySelectorAll('[data-recaptcha-protected]')
 *     .forEach(form => captchaObserver.observe(form))
 * })
 */

const captcha = {}

let isLoaded = false

let isLoading = false

const callbacks = []

captcha.load = () => {
  if (isLoading || isLoaded) return
  isLoading = true
  const script = document.createElement('script')
  script.addEventListener('load', () => {
    window.grecaptcha.ready(() => {
      let cb
      isLoaded = true
      while ((cb = callbacks.shift())) {
        cb()
      }
    })
  })
  script.setAttribute(
    'src',
    `https://www.google.com/recaptcha/api.js?render=${window.captchaKey}`
  )
  document.body.appendChild(script)
}

captcha.ready = cb => {
  if (isLoaded) {
    setTimeout(cb, 0)
  } else {
    callbacks.push(cb)
    if (!isLoading) {
      captcha.load()
    }
  }
}

captcha.protectForm = (form, callback = f => f.submit()) => {
  const input = document.createElement('input')
  input.setAttribute('type', 'hidden')
  input.setAttribute('name', 'g-recaptcha-response')
  form.appendChild(input)
  captcha.ready(() => {
    form.addEventListener('submit', e => {
      e.preventDefault()
      window.grecaptcha.execute(window.captchaKey).then(token => {
        input.value = token
        callback(form)
      })
    })
  })
}

export default captcha
