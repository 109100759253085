import './counter.styles.scss'

const clamp = (a, min, max) => {
  return Math.min(Math.max(a, min), max)
}

export const initCounters = page => {
  const counters = page.querySelectorAll('[data-counter]')

  counters.forEach(counter => {
    const label = document.querySelector(
      `[data-counter-label="${counter.dataset.counter}"]`
    )
    const value = counter.querySelector('[data-counter-value]')
    const input = counter.querySelector('[data-counter-input]')
    const buttonMinus = counter.querySelector('[data-counter-button-minus]')
    const buttonPlus = counter.querySelector('[data-counter-button-plus]')

    if (!value) {
      console.warn(`Counter "value" element is missing: [data-counter-value]`)
      return
    }

    if (!input) {
      console.warn(`Counter "input" element is missing: [data-counter-input]`)
      return
    }

    if (!buttonMinus) {
      console.warn(
        `Counter "button-minus" element is missing: [data-counter-button-minus]`
      )
      return
    }

    if (!buttonPlus) {
      console.warn(
        `Counter "button-plus" element is missing: [data-counter-button-plus]`
      )
    }

    const { min, max, initial } = input.dataset

    const forceUpdate = val => {
      const normalizedValue = clamp(val, min, max)

      if (label) {
        label.innerText = normalizedValue
      }

      value.innerText = normalizedValue
      input.value = normalizedValue

      input.dispatchEvent(new Event('change'))
    }

    forceUpdate(initial)

    buttonMinus.addEventListener('click', () => {
      forceUpdate(Number(input.value) - 1)
    })

    buttonPlus.addEventListener('click', () => {
      forceUpdate(Number(input.value) + 1)
    })
  })
}
