export const SELECTORS = {
  // triggers
  active: 'is-active',
  hidden: 'is-hidden',
  disabled: 'is-disabled',
  current: 'is-current',
  loading: 'is-loading',
  loaded: 'is-loaded',
  invalid: 'is-invalid',
  preloader_done: 'preloader_done',

  // modals
  menu_opened: 'menu-opened',
  has_modal: 'has-modal',
  has_cookies: 'has-cookies',
  has_preloader: 'has-preloader',

  // scroll
  no_scroll: 'no-scroll',
}
