/**
 * Entry point for webpack
 * Import styles, fonts, icons, scripts and other things that your project design requires here
 */

import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody'
import barba from '@barba/core'

import './styles/main.scss'
import './styles/_form.scss'
import './styles/_cta.scss'

import form from './form'

import { scrollToTop } from './components/scrollTotop'
import { lazyLoading } from './components/lazyLoading'
import {
  instagramSlider,
  mainSlider,
  relaxingSlider,
  gallerySlider,
  newsSlider,
  historyYearsSlider,
  courseSlider,
  instructorsSlider,
  roomsSlider,
  roomsMobileSlider,
  imagesSlider,
} from './components/swiperSlider'
import { customizeFormElements } from './form/customizeFormElements'
import { initHeader } from './components/header/header.scripts'
import { initDropdownMenu } from './components/dropdownMenu/dropdownMenu.scripts'
import { initMobileMenu } from './components/mobileMenu/mobileMenu.scripts'
import { scrollAnimations } from './animations/scroll-animations'
import { initPreloader } from './components/preloader/preloader'
import { initTextAnimation } from './components/textAnimation/textAnimation.scripts'
import { initGoogleMaps } from './components/googleMaps/googleMaps'
import { initTabs } from './components/tabs/tabs'
import { initModal } from './components/modal'
import { showMoreButton } from './components/showMoreButton/showMoreButton'
import { Dropdown } from './components/Dropdown/Dropdown'
import { initCheckboxDropdown } from './components/checkboxDropdown/checkboxDropdown.scripts'
import { initAutoSubmitForm } from './components/autoSubmitForm/autoSubmitForm.scripts'
import { cursor } from './components/cursor/cursor.scripts'
import { initRegularDropdown } from './components/regularDropdown/regularDropdown.scripts'
import { initCounters } from './components/counter/counter.scripts'
import { cartInputQuantity } from './components/cartInputQuantity/cartInputQuantity.scripts'
import { initSearchFormMobile } from './components/searchFormMobile/searchFormMobile.scripts'
import { fileInput } from './components/fileInput/fileInput.scripts'
import {
  initAccommodationBooking,
  initStepForm,
} from './components/stepForm/stepForm.scripts'
import {
  initEventsCalendar,
  initEventPopup,
} from './components/eventsCalendar/eventsCalendar.scripts'
import { initEventsView } from './components/eventsView/eventsView.scripts'
import { initGallery } from './components/galleryGrid/galleryGrid.scripts'
import { initRadioTabs } from './components/radioTabs/radioTabs.scripts'
import { initCovidPopup } from './components/covidPopup/covidPopup.scripts'
import { initBookLinksTrigger } from './components/bookLinks/bookLinks.scripts'
// fix HMR https://github.com/webpack-contrib/grunt-webpack/issues/132#issuecomment-292565027
if (module.hot) {
  module.hot.accept()
}

// initialize things like header, footer
const oneTimeThings = () => {
  // svg sprites support in IE11
  svg4everybody()

  // scroll to top button
  scrollToTop()

  // header
  initHeader()

  // initMenu
  initDropdownMenu()
  initMobileMenu()

  // cursor
  cursor.init()
}

// initialize things inside of data-barba="container"
const onPageLoad = page => {
  // init multiselect dropdown
  initCheckboxDropdown()

  // init regular dropdown
  initRegularDropdown(page)

  // init counters
  initCounters(page)

  // init cartInputQuantity
  cartInputQuantity()

  // object-fit support in IE11
  objectFitImages()

  // form
  page.querySelectorAll('[data-customize-inputs]').forEach(container => {
    customizeFormElements(container)
  })

  form(page)
  initAutoSubmitForm(page)
  initSearchFormMobile(page)
  initStepForm()
  initAccommodationBooking()

  // fileInput
  fileInput()

  // sliders
  document
    .querySelectorAll('[data-role="instagram-slider"]')
    .forEach(container => instagramSlider(container))

  document
    .querySelectorAll('[data-role="main-slider"]')
    .forEach(container => mainSlider(container))

  document
    .querySelectorAll('[data-role="relaxing-slider"]')
    .forEach(container => relaxingSlider(container))

  document
    .querySelectorAll('[data-role="gallery-slider"]')
    .forEach(container => gallerySlider(container))

  document
    .querySelectorAll('[data-role="news-slider"]')
    .forEach(container => newsSlider(container))

  document
    .querySelectorAll('[data-role="instructors-slider"]')
    .forEach(container => instructorsSlider(container))

  document
    .querySelectorAll('[data-role="images-slider"]')
    .forEach(container => imagesSlider(container))

  document
    .querySelectorAll('[data-role="history-slider"]')
    .forEach(container => {
      const sliderContainer = container.querySelector(
        '[data-history-slider="slider-container"]'
      )
      const tabsContainer = container.querySelector(
        '[data-history-slider="tabs-container"]'
      )

      if (!sliderContainer) {
        console.warn(
          '[History slider]: slider container is required: [data-history-slider="slider-container"]'
        )
        return
      }

      if (!tabsContainer) {
        console.warn(
          '[History slider]: tabs container is required: [data-history-slider="tabs-container"]'
        )
      }

      const swiperInstance = historyYearsSlider(sliderContainer)
      const tabsInstance = initTabs(
        tabsContainer,
        '[data-history-slider="tabs-container"]'
      )

      swiperInstance.on('slideChange', () => {
        if (window.innerWidth < 640) {
          tabsInstance.changeTab(swiperInstance.activeIndex)
        }
      })
    })

  document
    .querySelectorAll('[data-role="course-slider"]')
    .forEach(container => courseSlider(container))

  document
    .querySelectorAll('[data-role="rooms-slider"]')
    .forEach(container => roomsSlider(container))

  document
    .querySelectorAll('[data-role="rooms-mobile-slider"]')
    .forEach(container => roomsMobileSlider(container))

  // init google maps
  document
    .querySelectorAll('[data-role="google-maps-container"]')
    .forEach(el => initGoogleMaps(el))

  initTextAnimation(page)

  // tabs
  document
    .querySelectorAll('[data-tabs="container"]')
    .forEach(container => initTabs(container, '[data-tabs="container"]'))

  // init modal
  initModal(page)

  // more/less button
  showMoreButton()

  // init dropdown
  page
    .querySelectorAll('[data-role*="dropdown-container"]')
    .forEach(element => new Dropdown({ element }))

  // cursor hover effect
  page.querySelectorAll('[data-cursor]').forEach(el => {
    el.addEventListener('mouseenter', () => {
      if ('cursorColorClass' in el.dataset) {
        cursor.setColorClass(el.dataset.cursorColorClass)
      }
      cursor.toggleDisplay(true)
    })
    el.addEventListener('mouseleave', () => {
      cursor.toggleDisplay(false)
    })
  })

  page.querySelectorAll('[data-cursor] a').forEach(el => {
    el.addEventListener('mouseenter', () => {
      cursor.toggleDisplay(false)
    })
    el.addEventListener('mouseleave', () => {
      cursor.toggleDisplay(true)
    })
  })

  // events calendar
  initEventsCalendar()
  initEventPopup()

  // events events view
  initEventsView()

  // init gallery grid
  initGallery()

  // init radio tabs
  initRadioTabs()

  // init covid popup
  initCovidPopup()

  // init bookLinks trigger
  initBookLinksTrigger()

  // lazy loading images
  lazyLoading()
}

// things that require preloader to be removed
const afterPreloader = page => {
  scrollAnimations(page)
}

document.addEventListener('DOMContentLoaded', () => {
  oneTimeThings()

  const container = document.querySelector('[data-barba="container"]')
  onPageLoad(container)
  initPreloader().then(() => afterPreloader(document))

  const enableSmoothTransitions =
    !document.documentElement.classList.contains('ccm-toolbar-visible') &&
    document.documentElement.classList.contains('smooth-transitions-enabled')

  if (enableSmoothTransitions) {
    barba.init({
      // If server response is longer then 5s, page will reload completely.
      // You can set bigger values, but in case of long server response user should see something happens.
      timeout: 5000,
      transitions: [
        {
          enter({ next }) {
            onPageLoad(next.container)
            afterPreloader(next.container)
          },
        },
      ],
    })
  }
})
