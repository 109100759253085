import lightGallery from 'lightgallery'
import 'lightgallery/css/lightgallery.css'
import './galleryGrid.styles.scss'

export const initGallery = () => {
  const el = document.querySelector('[data-gallery]')

  if (!el) {
    return
  }

  lightGallery(el, {
    speed: 500,
    selector: '[data-gallery-item]',
    download: false,
    licenseKey: '99B5763A-C4064650-BC4A5A7C-FBDF4A63',
    nextHtml: `
      <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15.5L48.375 15.5" stroke="currentColor"/>
        <path d="M33.5 24C33.5 24 35.5284 15.5 48.375 15.5C35.5284 15.5 33.5 7 33.5 7" stroke="currentColor"/>
      </svg>
    `,
    prevHtml: `
      <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48 16.5L7.625 16.5" stroke="currentColor"/>
        <path d="M22.5 8C22.5 8 20.4716 16.5 7.625 16.5C20.4716 16.5 22.5 25 22.5 25" stroke="currentColor"/>
      </svg>
    `,
  })
}
