import './textAnimation.styles.scss'

export const initTextAnimation = page => {
  page.querySelectorAll('[data-text-animation]').forEach(container => {
    const animation = container.dataset.textAnimation
    const className = `text-animation-${animation}`

    const html = `
      <div class="out">
        <div class="in">
          ${container.innerHTML}
        </div>
      </div>
    `

    container.innerHTML = html
    container.classList.add(className)
  })
}
