import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import './modal.scss'
import { toggleScrollbar } from '../../lib/toggleScrollbar'

let modal
let closeButton
let modalContent

const setupHtml = () => {
  modal = document.createElement('div')
  modal.classList.add('modal')
  modal.setAttribute('role', 'dialog')
  modal.setAttribute('aria-modal', 'true')
  document.body.appendChild(modal)
  closeButton = document.createElement('button')
  closeButton.innerHTML = '×'
  closeButton.classList.add('modal__close')
  modal.appendChild(closeButton)
  modalContent = document.createElement('div')
  modalContent.classList.add('modal__content')
  modal.appendChild(modalContent)
  document.documentElement.classList.add('modal-ready')
}

const close = () => {
  toggleScrollbar(false)
  modal.classList.remove('modal_opened')
  enableBodyScroll(modal)
  setTimeout(() => {
    modalContent.innerHTML = ''
  }, 300)
}

export const initModal = container => {
  if (!document.documentElement.classList.contains('modal-ready')) {
    setupHtml()
    closeButton.addEventListener('click', close)
  }

  container.querySelectorAll('[data-modal-wrapper]').forEach(wrapper => {
    const toggle = wrapper.querySelector('[data-modal-toggle]')
    const content = wrapper.querySelector('[data-modal-content]')

    function open() {
      toggleScrollbar(true)
      modalContent.innerHTML = content.innerHTML
      modal.classList.add('modal_opened')
      disableBodyScroll(modal)
      document.body.style.marginRight = ''
    }

    toggle.addEventListener('click', open)
    toggle.addEventListener('keydown', e => {
      switch (e.keyCode) {
        case 13:
        case 32:
          open()
          break
        default: //
      }
    })
  })
  window.addEventListener('keydown', e => {
    switch (e.keyCode) {
      case 27:
        close()
        break
      default: //
    }
  })
}
