export const initBookLinksTrigger = () => {
  const bookLinksFooter = document.querySelector('[data-booklinks-footer]')
  const bookLinks = document.querySelector('[data-booklinks]')

  if (!bookLinks || !bookLinksFooter) {
    return
  }

  const bookLinksRect = bookLinks.getBoundingClientRect()

  const bookLinksTop = bookLinksRect.top
  const bookLinksHeight = bookLinksRect.height

  window.addEventListener(
    'wheel',
    () => {
      const footerRect = bookLinksFooter.getBoundingClientRect()
      const footerTop = footerRect.top

      bookLinks.classList.toggle(
        'is-static',
        footerTop <= bookLinksTop + bookLinksHeight + 24
      )
    },
    { passive: true }
  )
}
