// node-modules
import { TimelineMax, TweenMax } from 'gsap'

// constants
import { SELECTORS } from '../../lib/selectors'

// assets
import './Dropdown.scss'

const defaults = {
  element: null,
  triggerElement: '[data-role*=dropdown-title]',
  innerList: '[data-role*="dropdown-inner-list"]',
  innerListItem: '[data-role*="dropdown-inner-item"]',
  activeClass: SELECTORS.active,
  duration: 0.35,
}

export class Dropdown {
  constructor(props) {
    const options = { ...defaults, ...props }
    this.element = options.element
    this.triggerElements = this.element.querySelectorAll(options.triggerElement)
    this.innerList = this.element.querySelector(options.innerList)
    this.innerListItems = Array.from(
      this.element.querySelectorAll(options.innerListItem)
    )
    this.duration = options.duration
    this.durationStagger = options.duration * 0.8
    this.activeClass = options.activeClass
    this.activeStatus = false
    this.animated = false

    this.init()
  }

  toggleDropdown() {
    if (this.animated) {
      return
    }

    this.animated = true

    const tl = new TimelineMax({
      onComplete: () => {
        this.activeStatus = !this.activeStatus
        this.animated = false
      },
    })

    this.element.classList.toggle(this.activeClass)

    if (this.activeStatus) {
      tl.to(this.innerList, { duration: this.duration, height: 0 }).staggerTo(
        this.innerListItems,
        this.durationStagger,
        { opacity: 0, y: 20 },
        '.05',
        0
      )
    } else {
      TweenMax.set(this.innerList, { height: 'auto' })
      TweenMax.set(this.innerListItems, { opacity: 1, y: 30 })
      tl.from(this.innerList, { duration: this.duration, height: 0 }).staggerTo(
        this.innerListItems,
        this.durationStagger,
        { opacity: 1, y: 0, clearProps: 'all' },
        '.05',
        0
      )
    }
  }

  init() {
    this.triggerElements.forEach(trigger =>
      trigger.addEventListener('click', () => this.toggleDropdown())
    )
    TweenMax.set(this.innerList, { height: 0 })
  }
}
