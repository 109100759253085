import './mobileMenu.styles.scss'
import barba from '@barba/core'
import { SELECTORS } from '../../lib/selectors'
import { toggleScrollbar } from '../../lib/toggleScrollbar'

export const initMobileMenu = () => {
  const menuTriggers = document.querySelectorAll('[data-menu="trigger"]')
  const menuContent = document.querySelector('[data-menu="content"]')
  const menuLinks = document.querySelectorAll('[data-menu="links"] a')
  const menuDropdowns = document.querySelectorAll('[data-mobile-dropdown]')

  if (!menuLinks.length) {
    console.warn('At least one menu link required [data-menu="links"] a')
  }

  if (!menuTriggers.length) {
    console.warn('At least one menu trigger required [data-menu="trigger"]')
    return
  }

  if (!menuContent) {
    console.warn('Menu content required [data-menu="content"]')
    return
  }

  let timeout = null

  const toggleMenuState = opened => {
    document.documentElement.classList.toggle(SELECTORS.menu_opened, opened)
    clearTimeout(timeout)

    timeout = setTimeout(
      () => {
        toggleScrollbar(opened)
        document.documentElement.classList.toggle(SELECTORS.has_modal, opened)
      },
      opened ? 0 : 350
    )
  }

  const isMenuOpened = () =>
    document.documentElement.classList.contains(SELECTORS.menu_opened)

  menuTriggers.forEach(trigger =>
    trigger.addEventListener('click', () => {
      toggleMenuState(!isMenuOpened())
    })
  )

  menuDropdowns.forEach(element => {
    element.addEventListener('click', () => {
      element.classList.toggle(SELECTORS.active)
    })
  })

  barba.hooks.beforeLeave(() => {
    toggleMenuState(false)
    menuLinks.forEach(link => {
      link.classList.toggle(
        SELECTORS.active,
        link.href.endsWith(window.location.pathname)
      )
    })
  })
}
