export const initStepForm = () => {
  const stepForms = document.querySelectorAll('[data-step-form]')
  const steps = document.querySelectorAll('[data-step-form-step]')

  const changeActiveBlock = index => {
    stepForms.forEach((stepForm, i) => {
      stepForm.classList.toggle('step-form-hidden', i !== index)
    })

    steps.forEach((step, i) => {
      step.classList.toggle('is-active', index >= i)
    })
  }

  changeActiveBlock(0)

  stepForms.forEach(stepForm => {
    const inputs = stepForm.querySelectorAll('[data-step-input]')
    const button = stepForm.querySelector('[data-step-button]')

    if (!button) {
      return
    }

    const nextFormIndex = Number(button.dataset.nextStep)

    button.addEventListener('click', () => {
      let isValid = true

      inputs.forEach(input => {
        /* eslint-disable */
        if (input._flatpickr && input._flatpickr.altInput) {
          input = input._flatpickr.altInput
        }
        /* eslint-enable */

        if (!input.validity.valid) {
          isValid = false
          input.dispatchEvent(new Event('invalid'))
        }
      })

      if (isValid) {
        changeActiveBlock(nextFormIndex)
      }
    })
  })
}

export const initAccommodationBooking = () => {
  const accommodationInput = document.querySelector('[data-book-accommodation]')
  const accommodationBlock = document.querySelector(
    '[data-accommodation-block]'
  )

  if (!accommodationInput) {
    return
  }

  const inputs = accommodationBlock.querySelectorAll('[data-step-input]')
  const changeVisibility = () => {
    accommodationBlock.classList.toggle(
      'accommodation-block-hidden',
      accommodationInput.value === 'no' || !accommodationInput.value
    )

    inputs.forEach(input => {
      /* eslint-disable */
      if (input._flatpickr && input._flatpickr.altInput) {
        input = input._flatpickr.altInput
      }
      /* eslint-enable */

      if (accommodationInput.value === 'yes') {
        input.setAttribute('required', '')
      } else {
        input.removeAttribute('required')
      }
    })
  }

  changeVisibility()

  accommodationInput.addEventListener('change', changeVisibility)
}
