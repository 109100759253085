// node-modules
import Swiper, {
  Pagination,
  Navigation,
  EffectFade,
  Autoplay,
  Parallax,
} from 'swiper'

// assets
import 'swiper/swiper-bundle.min.css'
import { cursor } from './cursor/cursor.scripts'

Swiper.use([Pagination, Navigation, EffectFade, Autoplay, Parallax])

const sliderOptions = {
  speed: 1000,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  keyboard: true,
  observer: true,
  observeParents: true,
}

export function instagramSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 0,
      initialSlide: 0,
      slidesPerView: 5,
      effect: 'slide',
      autoplay: {
        delay: 2000,
      },
      breakpoints: {
        1024: {
          slidesPerView: 5,
        },
        640: {
          slidesPerView: 3.2,
        },
        0: {
          slidesPerView: 2.1,
        },
      },
    }
  )
}

export function newsSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 8,
      initialSlide: 0,
      slidesPerView: 1.2,
      effect: 'slide',
    }
  )
}

export function mainSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      speed: 600,
      spaceBetween: 0,
      initialSlide: 0,
      slidesPerView: 1,
      effect: 'fade',
      loop: true,
      loopAdditionalSlides: 5,
      pagination: {
        el: sliderContainer.querySelector('[data-role="slider-pagination"]'),
        type: 'fraction',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }
  )
}

export function relaxingSlider(sliderContainer) {
  const slides = sliderContainer.querySelectorAll('[data-slide-item]')

  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 0,
      initialSlide: 1,
      slidesPerView: 1,
      effect: 'fade',
      autoplay: {
        delay: 3000,
        waitForTransition: false,
      },
      allowTouchMove: false,
      simulateTouch: false,
      pagination: {
        el: sliderContainer.querySelector('[data-pagination]'),
        type: 'bullets',
        bulletClass: 'relaxing-bullet',
        bulletActiveClass: 'relaxing-bullet_active',
        clickable: true,
        renderBullet: (index, className) => {
          const slide = slides[index]
          if (!slide) {
            console.warn('[data-slide-item] missing for relaxing slider', {
              index,
            })
          }
          const { text, time, period } = slide.dataset

          return `
            <button class="${className}">
              <span
                class="${className}__time"
              >
                ${time}
                <span>${period}</span>
              </span>

              <span class="${className}__item">
                <span class="line"></span>
                <span class="bullet"></span>
              </span>

              <span
                class="${className}__text"
              >
                ${text}
              </span>
            </button>
          `
        },
      },
    }
  )
}

export function gallerySlider(sliderContainer) {
  const slides = sliderContainer.querySelectorAll('[data-slide-item]')

  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 0,
      initialSlide: 0,
      slidesPerView: 1,
      effect: 'fade',
      autoplay: {
        delay: 2000,
        waitForTransition: false,
      },
      allowTouchMove: false,
      simulateTouch: false,
      pagination: {
        el: sliderContainer.querySelector('[data-pagination]'),
        type: 'bullets',
        bulletClass: 'relaxing-bullet',
        bulletActiveClass: 'relaxing-bullet_active',
        clickable: true,
        renderBullet: (index, className) => {
          const slide = slides[index]
          if (!slide) {
            console.warn('[data-slide-item] missing for relaxing slider', {
              index,
            })
          }

          return `
            <button class="${className}">

              <span class="${className}__item">
                <span class="line"></span>
                <span class="bullet"></span>
              </span>
              </span>
            </button>
          `
        },
      },
    }
  )
}

export function historyYearsSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 0,
      initialSlide: 0,
      slidesPerView: 7,
      effect: 'slide',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1024: {
          slidesPerView: 7,
        },
        640: {
          slidesPerView: 4,
        },
        0: {
          slidesPerView: 1,
        },
      },
    }
  )
}

export function courseSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 8,
      initialSlide: 0,
      slidesPerView: 1,
      effect: 'slide',
      pagination: {
        el: sliderContainer.querySelector('[data-role="slider-pagination"]'),
        type: 'fraction',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }
  )
}

export function instructorsSlider(sliderContainer) {
  sliderContainer.addEventListener('mousedown', () => {
    cursor.toggleActiveState(true)
  })
  sliderContainer.addEventListener('mouseup', () => {
    cursor.toggleActiveState(false)
  })

  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 16,
      initialSlide: 0,
      slidesPerView: 3.5,
      effect: 'slide',
      touchStartPreventDefault: false, // required for correct work of cursor animations
      breakpoints: {
        1200: {
          slidesPerView: 3.5,
        },
        1024: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2.3,
        },
        0: {
          slidesPerView: 1.1,
        },
      },
    }
  )
}

export function roomsSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      speed: 2000,
      spaceBetween: 0,
      initialSlide: 0,
      slidesPerView: 1,
      effect: 'fade',
      autoplay: {
        delay: 2000,
      },
      pagination: {
        el: sliderContainer.querySelector('[data-role="slider-pagination"]'),
        type: 'bullets',
      },
    }
  )
}

export function roomsMobileSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 8,
      initialSlide: 0,
      slidesPerView: 1.1,
      effect: 'slide',
      breakpoints: {
        840: {
          slidesPerView: 2.1,
        },
        640: {
          slidesPerView: 1.5,
        },
        0: {
          slidesPerView: 1.1,
        },
      },
    }
  )
}

export function imagesSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      spaceBetween: 16,
      initialSlide: 0,
      slidesPerView: 4.1,
      effect: 'slide',
      breakpoints: {
        1024: {
          slidesPerView: 4.1,
        },
        840: {
          slidesPerView: 3.5,
        },
        640: {
          slidesPerView: 2.8,
          spaceBetween: 16,
        },
        0: {
          slidesPerView: 2.1,
          spaceBetween: 8,
        },
      },
    }
  )
}
