// node-modules
import { Loader } from 'google-maps'

// other
import mapStyles from './styles.json'

function getPoint(coords, google) {
  try {
    const { lat, lng } = JSON.parse(coords)
    return new google.maps.LatLng(lat, lng)
  } catch (e) {
    return false
  }
}

function addMarker(google, position, icon, map, restOptions = {}) {
  const marker = new google.maps.Marker({
    position,
    icon,
    optimized: false,
    ...restOptions,
  })
  marker.setMap(map)
  return marker
}

export function initGoogleMaps(el) {
  const { key, options, coords, center, markerImage } = el.dataset

  const loader = new Loader(key, { version: 'weekly' })
  loader.load().then(google => {
    const markerPosition = getPoint(coords, google)
    const centerPosition = getPoint(center, google) || markerPosition
    const map = new google.maps.Map(el, {
      center: centerPosition,
      styles: mapStyles,
      ...JSON.parse(options),
    })
    addMarker(google, markerPosition, markerImage, map)
  })
}
