export function cartInputQuantity() {
  const containers = document.querySelectorAll('[data-quantity-block]')

  if (containers) {
    containers.forEach(container => {
      const decrement = container.querySelector('[data-decrement]')
      const increment = container.querySelector('[data-increment]')
      const quantityInput = container.querySelector('[data-quantity-input]')

      decrement.addEventListener('click', () => {
        if (quantityInput.value <= 1) {
          decrement.classList.add('disabled')
        } else {
          decrement.classList.remove('disabled')
          quantityInput.value--
        }
      })

      increment.addEventListener('click', () => {
        quantityInput.value++
      })
    })
  }
}
