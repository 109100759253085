import { createObserverCallback } from './observation-callback'

const observerCallback = createObserverCallback(
  el => {
    el.classList.add('is-animated_in')
  },
  () => {}
)

const observer = new IntersectionObserver(
  entries => {
    entries.forEach(observerCallback)
  },
  { threshold: 0, rootMargin: '0px 0px -180px' }
)

export const scrollAnimations = page => {
  const elements = page.querySelectorAll('[data-animated]')
  observer.disconnect()
  elements.forEach(element => {
    element.classList.add('is-animated')
    observer.observe(element)
  })
}
