import { SELECTORS } from '../../lib/selectors'

export const initCovidPopup = () => {
  const KEY = 'has-seen-covid-popup'
  const popup = document.querySelector('[data-covid-popup]')
  const item = sessionStorage.getItem(KEY)

  if (!popup || item === 'yes') {
    return
  }

  const closePopupButtons = popup.querySelectorAll('[data-close-popup]')

  if (!closePopupButtons) {
    console.warn('"Close popup" button is required: \'[data-close-popup]\'')
    return
  }

  popup.classList.add(SELECTORS.active)

  closePopupButtons.forEach(button => {
    button.addEventListener('click', () => {
      popup.classList.remove(SELECTORS.active)
      sessionStorage.setItem(KEY, 'yes')
    })
  })
}
