/* eslint-disable no-console */
import { SELECTORS } from '../../lib/selectors'
import './preloader.scss'

const preloader = document.querySelector('[data-preloader]')
const preloaderToken = 'hasSeenPreloader'

const getPreloaderToken = () => !!sessionStorage.getItem(preloaderToken)

const setPreloaderToken = () => {
  sessionStorage.setItem(preloaderToken, 'true')
}

const showPreloaderAnimation = (timeout = 2000) =>
  new Promise(resolve => {
    if (!preloader) {
      console.warn('Preloader not found [data-preloader]')
      return
    }

    preloader.classList.add('is-animated')
    setTimeout(() => {
      resolve()
    }, timeout)
  })

const removePreloader = () => {
  document.documentElement.classList.remove(SELECTORS.no_scroll)
  document.documentElement.classList.remove(SELECTORS.has_preloader)

  document.documentElement.classList.add(SELECTORS.preloader_done)
}

export const initPreloader = () =>
  new Promise(resolve => {
    if (!getPreloaderToken()) {
      setPreloaderToken()
      showPreloaderAnimation().then(resolve)
    } else {
      resolve()
    }
  }).then(removePreloader)
