import './header.styles.scss'
import Headroom from 'headroom.js'
import { SELECTORS } from '../../lib/selectors'

export const initHeader = () => {
  const header = document.querySelector('[data-header="header"]')

  if (!header) {
    console.warn('Header required [data-header="header"]')
    return
  }

  const headroom = new Headroom(header, {
    offset: 300,
    classes: {
      notTop: SELECTORS.active,
      unpinned: 'header--unpinned',
      pinned: 'header--pinned',
    },
  })
  headroom.init()

  // const callback = entries => {
  //   entries.forEach(entry => {
  //     header.classList.toggle(SELECTORS.active, !entry.isIntersecting)
  //   })
  // }
}
