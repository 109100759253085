import html5Validation from './html5-validation'
import customInputs from './custom-inputs'
import lazyRecaptcha from './lazy-recaptcha'
import animatedFormSubmission from './animated-form-submission'

const afterCaptcha = form =>
  animatedFormSubmission(form).then(({ error, response, success }) => {
    response.text().then(html => {
      const backendErrors = form.querySelector('[data-backend-errors]')
      const div = document.createElement('div')
      div.innerHTML = html
      const backendErrorsInResponse = div.querySelector(
        '[data-backend-errors="yes"]'
      )
      if (backendErrorsInResponse) {
        backendErrors.innerHTML = backendErrorsInResponse.innerHTML
        error()
      } else {
        success()
        // here you can add code to show popup
      }
    })
  })

const captchaObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      lazyRecaptcha.protectForm(entry.target, afterCaptcha)
      captchaObserver.unobserve(entry.target)
    }
  })
})

export default page => {
  customInputs(page)

  // validate inputs, call after customInputs because flatpickr creates extra hidden input
  html5Validation(page)

  captchaObserver.disconnect()
  page
    .querySelectorAll('[data-recaptcha-protected]')
    .forEach(form => captchaObserver.observe(form))

  page
    .querySelectorAll(
      '[data-animated-submission]:not([data-recaptcha-protected])'
    )
    .forEach(el => {
      el.addEventListener('submit', e => {
        e.preventDefault()
        afterCaptcha(el)
      })
    })
}
