export const initAutoSubmitForm = page => {
  const forms = page.querySelectorAll('[data-autosubmit-form]')

  forms.forEach(form => {
    const submitTriggerInput = form.querySelectorAll(
      '[data-submit-trigger-input]'
    )

    submitTriggerInput.forEach(input => {
      input.addEventListener('change', () => {
        form.submit()
      })
    })
  })
}
