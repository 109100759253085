import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'

import tippy from 'tippy.js'
import './eventsCalendar.styles.scss'
import { SELECTORS } from '../../lib/selectors'

const locales = {
  fr: frLocale,
}

export const initEventsCalendar = () => {
  const calendarEl = document.querySelector('[data-events-calendar]')

  if (!calendarEl) {
    return
  }

  const events = window.ddgEvents

  if (!window.ddgEvents) {
    console.warn('window.events property is required to proceed')
  }

  const lang = document.documentElement.getAttribute('lang')
  const calendar = new Calendar(calendarEl, {
    plugins: [dayGridPlugin],
    height: 'auto',
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next',
    },
    eventDidMount({ event, el }) {
      const date = `0${event.start.getDate()}`.slice(-2)

      tippy(el, {
        content: `
          <div class="event-tooltip">
            <p class="event-tooltip__date">${date}</p>
            <p class="event-tooltip__title">${event.title}</p>
            <p class="event-tooltip__status">${event.extendedProps.status}</p>

            <ul class="event-tooltip__properties event-properties">
                <li class="event-properties__item">
                  <svg class="event-properties__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.2243 7.30737H20.2409C20.2409 7.30737 20.7208 13.2881 15.043 13.5312" stroke="currentColor" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2699 6.31506V5.45747H18.2596V4.46899H12.0321H11.9411H5.71484V5.45747H6.70457V6.31506C6.70457 9.69184 6.83545 11.5491 9.48801 14.092C10.2384 14.8125 10.6622 15.2812 10.6622 15.8795C10.6622 16.5301 10.2409 17.0848 9.65628 17.2843H7.91617V20.2859H11.9411H12.0321H16.0571V17.2843H14.3182C13.7336 17.0848 13.3123 16.5301 13.3123 15.8795C13.3123 15.2812 13.7361 14.8125 14.4865 14.092C17.139 11.5491 17.2699 9.69184 17.2699 6.31506Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.7765 7.30737H3.75997C3.75997 7.30737 3.28131 13.2881 8.95788 13.5312" stroke="currentColor" stroke-linejoin="round"/>
                  </svg>
                  <p class="event-properties__text">
                    ${event.extendedProps.category}
                  </p>
                </li>

                <li class="event-properties__item">
                  <svg class="event-properties__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25Z" stroke="currentColor" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 7.5V12H16.5" stroke="currentColor" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <p class="event-properties__text">
                    ${event.extendedProps.registrationPeriod}
                  </p>
                </li>
            </ul>

            <p class="event-tooltip__description">
              ${event.extendedProps.description}
            </p>

            ${
              event.extendedProps.registrationAvailable
                ? `
                <button
                  class="event-tooltip__cta"
                  type="button"
                  data-event-registration
                  data-event-id="${event.id}"
                  data-event-name="${event.title}"
                >
                  ${event.extendedProps.registrationText}
                </button>
              `
                : ''
            }

          </div>
        `,
        allowHTML: true,
        interactive: true,
        placement: 'auto',
        offset: 0,
      })
    },
    events,
    locale: locales[lang],
  })

  calendar.render()

  const htmlClassObserver = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (
        mutation.type !== 'attributes' &&
        mutation.attributeName !== 'class'
      ) {
        return
      }

      if (document.documentElement.classList.contains('calendar-view')) {
        calendar.render()
      }
    })
  })

  htmlClassObserver.observe(document.documentElement, { attributes: true })
}

export const initEventPopup = () => {
  const eventPopup = document.querySelector('[data-event-popup]')

  if (!eventPopup) {
    return
  }

  const titleElement = eventPopup.querySelector('[data-event-title]')
  const eventIdInput = eventPopup.querySelector('[data-event-id]')
  const popupCloseButton = eventPopup.querySelector('[data-close-button]')

  if (!titleElement) {
    console.warn('event title is missing in popup [data-event-title]')

    return
  }

  if (!eventIdInput) {
    console.warn('event id input is missing in popup [data-event-id]')

    return
  }

  if (!popupCloseButton) {
    console.warn('popup close button is missing in popup [data-close-button]')

    return
  }

  popupCloseButton.addEventListener('click', () => {
    eventPopup.classList.remove(SELECTORS.active)
  })

  document.addEventListener('click', event => {
    const eventButton = event.target.closest('[data-event-registration]')

    if (!eventButton) {
      return
    }

    eventPopup.classList.add(SELECTORS.active)
    titleElement.innerText = eventButton.dataset.eventName
    eventIdInput.value = eventButton.dataset.eventId
  })
}
