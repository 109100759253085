import { SELECTORS } from '../../lib/selectors'

export const initSearchFormMobile = page => {
  const visibleClassName = 'hotel-search-visible'

  const searchForm = page.querySelector('[data-search-form-mobile]')

  if (!searchForm) {
    return
  }

  const triggerButton = page.querySelector('[data-mobile-search-toggle]')

  if (!triggerButton) {
    console.warn('Trigger button [data-mobile-search-toggle] is missing')
    return
  }

  triggerButton.addEventListener('click', () => {
    document.documentElement.classList.add(visibleClassName)
  })

  const steps = searchForm.querySelectorAll('[data-step]')

  const changeStep = index => {
    steps.forEach(step =>
      step.classList.toggle(
        SELECTORS.active,
        Number(step.dataset.step) === Number(index)
      )
    )
  }

  changeStep(0)

  const closeButtons = searchForm.querySelectorAll('[data-hotel-search-close]')

  closeButtons.forEach(button => {
    button.addEventListener('click', () => {
      document.documentElement.classList.remove(visibleClassName)
    })
  })

  const nextStepButtons = searchForm.querySelectorAll('[data-next-step]')

  nextStepButtons.forEach(button => {
    button.addEventListener('click', () => {
      changeStep(button.dataset.nextStep)
    })
  })

  const prevStepButtons = searchForm.querySelectorAll('[data-prev-step]')

  prevStepButtons.forEach(button => {
    button.addEventListener('click', () => {
      changeStep(button.dataset.prevStep)
    })
  })

  const roomInputs = Array.from(
    searchForm.querySelectorAll('[data-room-input]')
  )
  const roomMeta = searchForm.querySelectorAll('[data-meta="room"]')

  const setRoomMeta = () => {
    const checkedInput = roomInputs.find(el => el.checked)

    if (checkedInput) {
      roomMeta.forEach(meta => (meta.innerText = checkedInput.dataset.text))
    } else {
      roomMeta.forEach(meta => (meta.innerText = meta.dataset.initial))
    }
  }

  setRoomMeta()

  roomInputs.forEach(input => input.addEventListener('change', setRoomMeta))

  const dateInput = searchForm.querySelector('[data-date-input] ~ input')
  const dateMeta = searchForm.querySelectorAll('[data-meta="date"]')
  const dateButton = searchForm.querySelector('[data-date-button]')

  if (dateInput) {
    const setDateMeta = () => {
      if (dateInput.value) {
        dateMeta.forEach(meta => (meta.innerText = dateInput.value))
        if (dateButton) {
          dateButton.removeAttribute('disabled')
        }
      } else {
        dateMeta.forEach(meta => (meta.innerText = meta.dataset.initial))
        if (dateButton) {
          dateButton.setAttribute('disabled', '')
        }
      }
    }

    setDateMeta()

    dateInput.addEventListener('change', setDateMeta)
  } else {
    console.warn(
      'Date input not found for mobile search form "[data-date-input]"'
    )
  }

  const guestsInputs = Array.from(
    searchForm.querySelectorAll('[data-guests-input]')
  )

  const setGuestsMeta = (type, value) => {
    const guestsMeta = searchForm.querySelectorAll(`[data-meta="${type}"]`)

    guestsMeta.forEach(meta => (meta.innerText = value))
  }

  guestsInputs.forEach(input => {
    setGuestsMeta(input.dataset.guestsInput, input.value)
    input.addEventListener('change', () =>
      setGuestsMeta(input.dataset.guestsInput, input.value)
    )
  })
}
