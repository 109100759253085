import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'
import 'flatpickr/dist/themes/airbnb.css'
import './_date.scss'

const locales = {
  fr: French,
}

const flatpickrConfig = {
  // enable altInput and adjust altFormat as your design requires
  altFormat: 'M d, Y',
  altInput: true,

  // this allows editing but also enables HTML5 validation
  allowInput: true,

  dateFormat: 'Y-m-d',

  disableMobile: false,

  locale: {
    rangeSeparator: ' - ',
  },
}

// on desktop we use flatpickr
export const desktopDatepicker = ({ input, toggleFocus }) => {
  let fp
  const config = { ...flatpickrConfig }
  const lang = document.documentElement.getAttribute('lang')
  if (locales[lang]) {
    config.locale = locales[lang]
  }
  config.onOpen = toggleFocus.bind(null, true)
  config.onClose = toggleFocus.bind(null, false)
  config.appendTo = input.parentNode

  // trigger re-validation
  config.onChange = () => {
    if (fp.altInput) {
      fp.altInput.dispatchEvent(new Event('input'))
      fp.altInput.dispatchEvent(new Event('change'))
    }
  }

  fp = flatpickr(input, config)

  if (fp.altInput) {
    const events = ['keydown', 'cut', 'paste']
    events.forEach(evtType => (fp.altInput[`on${evtType}`] = () => false))
  }
}

// on Android/IOS, we use native datepickers
// but their format is only like 2020-04-25
// while Concrete5 date format is only like 4/25/2020
// so we create hidden input to hold value in correct format instead
export const mobileDatepicker = input => {
  const hiddenInput = document.createElement('input')
  hiddenInput.type = 'hidden'
  hiddenInput.name = input.name
  input.removeAttribute('name')
  input.parentElement.insertBefore(hiddenInput, input)

  const setHiddenValue = value => {
    if (value === '') return
    const [y, m, d] = value.split('-')
    hiddenInput.value = `${m}/${d}/${y.substr(2, 2)}`
  }

  const initialValueFromServer = input.getAttribute('value')
  if (initialValueFromServer) {
    const [m, d, y] = initialValueFromServer.split('/')
    const yy = y > 70 ? `19${y}` : `20${y}` // this code will break before the end of XXI century haha I love Concrete5
    const mm = m < 10 ? `0${m}` : `${m}`
    input.value = `${yy}-${mm}-${d}`
  }

  setHiddenValue(input.value)

  input.addEventListener('change', () => {
    setHiddenValue(input.value)
  })
}
