import './regularDropdown.styles.scss'

/* eslint-disable no-console */
export const initRegularDropdown = page => {
  page.querySelectorAll('[data-dropdown]').forEach(element => {
    const button = element.querySelector('[data-dropdown-button]')
    const list = element.querySelector('[data-dropdown-list]')

    if (!button) {
      console.warn('Dropdown button is required: "[data-dropdown-button]"')
      return
    }

    if (!list) {
      console.warn('Dropdown list is required: "[data-dropdown-list]"')
    }

    const elements = [element, list]
    // const show = () => {
    //   elements.forEach(el => el.classList.add('active'))
    // }

    const hide = () => {
      elements.forEach(el => el.classList.remove('active'))
    }

    const toggle = () => {
      elements.forEach(el =>
        el.classList.toggle('active', !el.classList.contains('active'))
      )
    }

    button.addEventListener('click', toggle)

    window.addEventListener('click', e => {
      if (element.contains(e.target)) {
        return
      }

      hide()
    })
  })
}
