import { SELECTORS } from '../../lib/selectors'
import './tabs.scss'

export const initTabs = (container, containerSelector) => {
  const tabIndexAttr = 'data-tab-index'
  const tabItems = Array.from(
    container.querySelectorAll(`[data-tab="item"]`)
  ).filter(el => el.closest(containerSelector) === container)

  const tabTriggers = Array.from(
    container.querySelectorAll(`[data-tab="trigger"]`)
  ).filter(el => el.closest(containerSelector) === container)

  const mobileSelect = container.querySelector(`[data-tab="mobile-select"]`)

  if (!tabItems.length) {
    console.warn('Tab items required to init tabs: [data-tab="item"]')

    return { changeTab: () => {} }
  }

  if (!tabTriggers.length) {
    console.warn('Tab triggers required to init tabs: [data-tab="trigger"]')

    return { changeTab: () => {} }
  }

  const changeTab = index => {
    const tabIndex = index.toString()

    tabTriggers.forEach(tabTrigger => {
      if (!tabTrigger.hasAttribute(tabIndexAttr)) {
        console.warn(
          `Trigger doesn't have required attribute [${tabIndexAttr}]`,
          tabTrigger
        )

        return
      }

      tabTrigger.classList.toggle(
        SELECTORS.active,
        tabTrigger.dataset.tabIndex === tabIndex
      )
    })

    tabItems.forEach(tabItem => {
      if (!tabItem.hasAttribute(tabIndexAttr)) {
        console.warn(
          `Tab item doesn't have required attribute [${tabIndexAttr}]`,
          tabItem
        )

        return
      }

      tabItem.classList.toggle(
        SELECTORS.active,
        tabItem.dataset.tabIndex === tabIndex
      )
    })

    if (mobileSelect) {
      mobileSelect.value = tabIndex
    }
  }

  tabTriggers.forEach(trigger => {
    trigger.addEventListener('click', e => {
      e.preventDefault()

      changeTab(trigger.dataset.tabIndex)

      trigger.classList.add(SELECTORS.active)
    })
  })

  if (mobileSelect) {
    mobileSelect.addEventListener('change', event => {
      changeTab(event.target.value)
    })
  }

  const activeTabTrigger = tabTriggers.find(el =>
    el.classList.contains(SELECTORS.active)
  )

  if (activeTabTrigger) {
    const activeTabItem = tabItems.find(
      el => el.dataset.tabIndex === activeTabTrigger.dataset.tabIndex
    )

    if (!activeTabItem) {
      console.warn(
        `Can't init active tab. Tab item with data-index not found`,
        `[${tabIndexAttr}=${activeTabTrigger.dataset.tabIndex}]`,
        activeTabTrigger
      )

      return { changeTab: () => {} }
    }

    activeTabItem.classList.add(SELECTORS.active)
  }

  return {
    changeTab,
  }
}
