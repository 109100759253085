import './dropdownMenu.styles.scss'
import { SELECTORS } from '../../lib/selectors'
import { isTouchDevice } from '../../lib/isTouchDevice'

export const initDropdownMenu = () => {
  const menu = document.querySelector('[data-dropdown-menu]')
  const menuLinks = Array.from(document.querySelectorAll('[data-menu-link]'))
  const menuBlocks = Array.from(document.querySelectorAll('[data-menu-block]'))

  if (!menu) {
    console.warn('Menu is missing [data-dropdown-menu]')
    return
  }

  if (menuLinks.length !== menuBlocks.length) {
    console.warn(
      'MenuLinks [data-menu-link] and MenuBlocks [data-menu-block] lengths mismatch'
    )
    console.warn({ menuLinks, menuBlocks })

    return
  }

  const MENU_HOVERED = 'dropdown-menu-hovered'
  const MENU_OPENED = 'dropdown-menu-opened'

  let timeout = null

  const toggleBlocks = name => {
    menuBlocks.forEach(block => {
      block.classList.toggle(SELECTORS.active, block.dataset.menuBlock === name)
    })
  }

  const toggleLinks = name => {
    menuLinks.forEach(link => {
      link.classList.toggle(SELECTORS.active, link.dataset.menuLink === name)
    })
  }

  const closeMenu = (delay = 100) => {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      if (!document.documentElement.classList.contains(MENU_HOVERED)) {
        document.documentElement.classList.remove(MENU_OPENED)

        menuLinks.forEach(link => {
          link.classList.remove(SELECTORS.active)
        })
      }
    }, delay)
  }

  menuLinks.forEach(link => {
    link.addEventListener('mouseenter', () => {
      if (isTouchDevice()) {
        return
      }

      clearTimeout(timeout)

      toggleLinks(link.dataset.menuLink)

      document.documentElement.classList.add(MENU_OPENED)

      toggleBlocks(link.dataset.menuLink)
    })

    link.addEventListener('click', e => {
      e.preventDefault()

      clearTimeout(timeout)

      link.classList.add(SELECTORS.active)

      const activeBlock = menuBlocks.find(
        block => block.dataset.menuBlock === link.dataset.menuLink
      )

      if (
        document.documentElement.classList.contains(MENU_OPENED) &&
        activeBlock.classList.contains(SELECTORS.active)
      ) {
        closeMenu(0)
      } else {
        document.documentElement.classList.add(MENU_OPENED)
        toggleLinks(link.dataset.menuLink)
      }

      toggleBlocks(link.dataset.menuLink)
    })

    link.addEventListener('mouseleave', () => {
      closeMenu()
    })
  })

  menu.addEventListener('mouseenter', () => {
    document.documentElement.classList.add(MENU_HOVERED)
  })

  menu.addEventListener('mouseleave', () => {
    document.documentElement.classList.remove(MENU_HOVERED)
    closeMenu()
  })

  document.addEventListener('click', e => {
    if (
      [...menuLinks, ...menuBlocks].some(element => element.contains(e.target))
    ) {
      return
    }

    closeMenu(0)
  })
}
