import { SELECTORS } from '../../lib/selectors'
import './radioTabs.styles.scss'

export const initRadioTabs = () => {
  const inputs = [...document.querySelectorAll('[data-radio-tab-input]')]
  const containers = [
    ...document.querySelectorAll('[data-radio-tab-container]'),
  ]

  const changeActiveTab = () => {
    inputs.forEach(input => {
      if (!input.checked) {
        return
      }

      const namedContainers = containers.filter(
        container => container.dataset.name === input.name
      )

      namedContainers.forEach(container => {
        container.classList.toggle(
          SELECTORS.active,
          input.dataset.showContainer === container.dataset.container
        )
      })
    })
  }

  changeActiveTab()

  inputs.forEach(input => input.addEventListener('change', changeActiveTab))
}
