import './cursor.styles.scss'

export const cursor = {
  colorClasses: [],
  init() {
    const events = ['mousemove', 'drag']
    cursor.element = document.querySelector('.cursor')
    cursor.bgElement = cursor.element.querySelector('.cursor__inner')
    events.forEach(type =>
      document.addEventListener(type, e => {
        cursor.element.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`
      })
    )
  },
  toggleDisplay(enable) {
    cursor.element.classList.toggle('cursor--show', enable)
  },
  toggleActiveState(enable) {
    cursor.element.classList.toggle('cursor--active', enable)
  },
  setColorClass(classesString) {
    cursor.colorClasses.forEach(className =>
      cursor.bgElement.classList.remove(className)
    )
    cursor.colorClasses = classesString.split(' ')
    cursor.colorClasses.forEach(className =>
      cursor.bgElement.classList.add(className)
    )
  },
}
